import { AuthorizationContext } from 'context';
import React, { useContext } from 'react';

const PrivateElement = ({ scope, children }) => {
  const context = useContext(AuthorizationContext);
  const [permissions] = context || [];

  if (permissions?.[scope]) {
    return <>{children}</>;
  }

  return null;
};

export default PrivateElement;
